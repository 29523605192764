import React from 'react'
import './main-home.css'

function MainHome() {
  return (
    <div className='home-container'>
      <h1>World Archery México</h1>
      <p>¡Proximamente!</p>
        <div className='img-home-container'>

        </div>
      <p className='copyright'>Copyright © 2024 World Archery Mexico - Todos los derechos reservados.</p>
    </div>
  )
}

export default MainHome