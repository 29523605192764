import './App.css';
import MainHome from './views/main-home';

function App() {
  return (
    <>
      <MainHome/>
    </>
  );
}

export default App;
